<template>
  <div>
    <!--begin::Employees-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Create Role</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="ma-2 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToRolesList()">
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <v-form ref="role_form" id="kt_role_form" v-model="valid" lazy-validation>
            <v-row no-gutters>
              <v-text-field
                cols="12"
                sm="12"
                v-model="role.name"
                :rules="nameRule"
                label="Role Name"
                required
                outlined
                dense
                class="pr-2"
              ></v-text-field>
            </v-row>
            <v-container fluid class="card-footer pt-3">
              <v-btn
                v-if="!is_update"
                :disabled="!valid"
                color="success"
                id="role_submit"
                class="mr-4"
                @click="validate"
              >
              <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                Submit
              </v-btn>
              <v-btn
                v-else
                :disabled="!valid"
                color="success"
                id="role_submit"
                class="mr-4"
                @click="validate"
              >
              <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                Update
              </v-btn>
            </v-container>
          </v-form>
        </div>
        <!-- </form> -->
      </div>
    </div>
    <!--end::Employees-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import EventBus from "@/core/services/store/event-bus";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { STORE_ROLE, UPDATE_ROLE } from "@/core/services/store/committee.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

export default {
  name: "RoleForm",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      role: {
        name: "",
        action_name: []
      },
      is_update: false,
      valid: true,
      disable_btn: false,
      transfer: false,
      onboarding: false,
      nameRule: [
        v => !!v || "Name is required",
        v =>
          (v && v.length <= 25) || "Name must be less than 25 characters"
      ]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    let context = this;

    if (this.$route.params.id) {
      context.is_update = true;
      context.edit_form = true;
      axios({
        method: "get",
        url: "committee-roles/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.committeeRoleDetails) {
            context.role.name = result.data.committeeRoleDetails.name;
            context.role.id = result.data.committeeRoleDetails.id;
            result.data.committeeRoleDetails.actions.forEach(action => {
              if(action.action_name == "Transfer Checklist"){
                context.transfer = true;
              }
              if(action.action_name == "Onboarding Questions"){
                context.onboarding = true;
              }
            });
          } else {
            context.$router.go(-1); //go back to list
            Swal.fire(result.data.error, "Error in getting role data.", "error");
          }
        },
        function(error) {
          Swal.fire("Error", "Role not found.", "error");
          context.$router.push({ name: "committee.roles" });
        }
      );
    }

    // set the tab from previous
    context.$store.dispatch(SET_BREADCRUMB, [{ title: "Role" }]);

    const role_form = KTUtil.getById("kt_role_form");

    context.fv = formValidation(role_form, {});
    EventBus.$on("STORE_ROLE", function(payLoad) {
      if (payLoad) {
        Swal.fire("Success", "Role stored successfully.", "success");
        context.redirectToRolesList();
      } else {
        Swal.fire("Error", "Error in storing role.", "error");
      }
    });
    EventBus.$on("UPDATE_ROLE", function(payLoad) {
      if (payLoad) {
        Swal.fire("Success", "Role updated successfully.", "success");
        context.redirectToRolesList();
      }
    });

    EventBus.$on("UPDATE_ROLE_ERROR", function(payLoad) {
      context.$router.push({ name: "committee.roles" });
    });

  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    redirectToRolesList() {
      let context = this;
      context.$router.push({ name: "committee.roles" });
    },
    updateRole() {
      let context = this;
      context.valid = false;
      if(context.transfer) {
        context.role.action_name.push("Transfer Checklist");
      }
      if(context.onboarding) {
        context.role.action_name.push("Onboarding Questions");
      }
      this.$store.dispatch(UPDATE_ROLE, context.role).then(() => {
          context.disable_btn = false;
      });
    },
    submitRole() {
      let context = this;
      context.valid = false;
      if(context.transfer) {
        context.role.action_name.push("Transfer Checklist");
      }
      if(context.onboarding) {
        context.role.action_name.push("Onboarding Questions");
      }
      this.$store.dispatch(STORE_ROLE, this.role).then(() => {
          context.disable_btn = false;
      });
    },
    validate() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.role_form.validate()) {
        context.disable_btn = true;
        if(this.$route.params.id) {
          this.updateRole();
        } else {
          this.submitRole();
        }
      } else {
        this.snackbar = true;
      }
    }
  }
};
</script>
